<!-- 登录 -->
<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/logo1.png" alt />
    </div>
    <div class="loginbox">
      <!--            <img src="../assets/fj1.jpg" class="fenj" alt/>-->
      <div class="fenj"></div>
      <div class="logoform">
        <el-tabs v-model="activeName">
          <el-tab-pane label="账号登录" name="first">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="80px"
              label-position="top"
              @submit.native.prevent
            >
              <el-form-item prop="username">
                <el-input
                  v-model="form.username"
                  clearable
                  placeholder="登录账号：邮箱或其他字符"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="form.password"
                  clearable
                  show-password
                  type="password"
                  placeholder="请输入密码(大小写字母+符号+数字)"
                ></el-input>
              </el-form-item>
              <el-form-item prop="imageVerifyCode">
                <el-row>
                  <el-col :span="14">
                    <el-input
                      v-model="form.imageVerifyCode"
                      clearable
                      placeholder="请输入图形码"
                      @keyup.enter.native="onSubmit('form')"
                    ></el-input>
                  </el-col>
                  <el-col :span="8" :offset="1">
                    <div @click="requestImg">
                      <!--suppress HtmlUnknownTarget -->
                      <img :src="image" alt="快点我一下" />
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  :disabled="onSubmitButton"
                  size="medium"
                  @click="onSubmit('form')"
                  style="width: 100%;"
                  class="text"
                  >登录
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="手机号登录" name="second">
            <el-form
              ref="cellphone"
              :model="cellphone"
              :rules="cellphonerules"
              label-width="80px"
              label-position="top"
              @submit.native.prevent
            >
              <el-form-item prop="cellphone">
                <el-input
                  v-model="cellphone.cellphone"
                  clearable
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="imageVerifyCode">
                <el-row>
                  <el-col :span="14">
                    <el-input
                      v-model="cellphone.imageVerifyCode"
                      clearable
                      placeholder="请输入图形码"
                      @keyup.enter.native="telSubmit('cellphone')"
                    ></el-input>
                  </el-col>
                  <el-col :span="8" :offset="1">
                    <div @click="requestImg">
                      <!--suppress HtmlUnknownTarget -->
                      <img :src="image" alt="快点我一下" />
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item prop="smsVerifyCode">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-input
                      placeholder="请输入短信验证码"
                      @keyup.enter.native="telSubmit('cellphone')"
                      v-model="cellphone.smsVerifyCode"
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      type="mini"
                      @click="loginCode()"
                      v-if="cellphone.cellphone"
                      :disabled="disabled"
                      >{{ btntxt }}
                    </el-button>
                    <el-button type="mini" v-else :disabled="true">{{
                      btntxt
                    }}</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  :disabled="onSubmitButton"
                  size="medium"
                  @click="telSubmit('cellphone')"
                  style="width: 100%;"
                  class="text"
                  >登录
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <a
            id="password-a"
            @click="requestForget"
            class="fl"
            style="cursor:pointer;font-size: 12px;"
            >忘记密码？</a
          >
          <a href="/?#/account/register" class="reglink"
            >还没有账号？ 去注册 >></a
          >
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="忘记密码"
      :visible.sync="forgetDialog"
      width="30%"
      center
      lock-scroll
      v-loading="loading"
      :showClose="showClo"
    >
      <el-form
        ref="newForm"
        :model="newForm"
        :rules="newRules"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            placeholder="请输入用户名"
            clearable
            v-model="newForm.username"
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="newPassword">
          <el-input
            placeholder="请输入新密码"
            show-password
            type="password"
            clearable
            v-model="newForm.newPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            placeholder="请确定密码"
            show-password
            type="password"
            clearable
            v-model="newForm.confirmPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="imageVerifyCode">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                placeholder="请输入图形验证码"
                clearable
                v-model="newForm.imageVerifyCode"
              />
            </el-col>
            <el-col :span="8" :offset="2">
              <div>
                <!--suppress HtmlUnknownTarget -->
                <img :src="image" alt="快点我一下" @click="requestImg" />
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="验证码" prop="smsVerifyCode">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                placeholder="请输入短信验证码"
                v-model="newForm.smsVerifyCode"
              />
            </el-col>
            <el-col :span="8" :offset="2">
              <el-button
                type="mini"
                @click="requestCode"
                v-text="btntxt"
                :disabled="disabled"
              >
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button type="primary" @click="forgetPassword('newForm')"
                >确定</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button type="primary" @click="back('newForm')"
                >取消</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="footer">
      主办: 赣州经济技术开发区党工委人才办
      <br />Copyright 2020 All Rights Reserved
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UrlSearch from "@/utils/urlSeach";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.newForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      onSubmitButton: false,
      activeName: "first",
      showClo: false,
      loading: false,
      rotate: "",
      accountId: "",
      time: 60,
      disabled: false,
      btntxt: "获取验证码",
      form: {
        username: "",
        password: "",
        imageVerifyCode: "",
        imageVerifyId: "",
      },
      cellphone: {
        cellphone: "",
        imageVerifyCode: "",
        imageVerifyId: "",
        smsVerifyCode: "",
        smsVerifyId: "",
      },
      forgetDialog: false,
      newForm: {
        smsVerifyId: "",
        smsVerifyCode: "",
        imageVerifyId: "",
        imageVerifyCode: "",
        newPassword: "",
        confirmPassword: "",
        username: "",
      },
      image: "",
      newRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          // {required: true, message: "确认新密码", trigger: "blur"},
          // {
          //     pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
          //     message: "密码格式为：Aa#123；且不可以小于6位",
          //     trigger: "blur"
          // }
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        imageVerifyCode: {
          required: true,
          message: "请输入图形码",
          trigger: "blur",
        },
        smsVerifyCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "用户名只能为中文和字母，且长度在 5 到 20 个字符",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z\u4e00-\u9fa5]+$/,
            message: "用户名只能为中文和字母，且长度在 5 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        username: {
          required: true,
          message: "请输入邮箱或其他字符",
          trigger: "blur",
        },
        imageVerifyCode: {
          required: true,
          message: "请输入图形码",
          trigger: "blur",
        },

        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认新密码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        image: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        codeNumber: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      cellphonerules: {
        cellphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        imageVerifyCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        smsVerifyCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      userninformation: "",
    };
  },

  created() {
    console.log(UrlSearch().name);
    if (UrlSearch().name) {
      this.activeName = UrlSearch().name;
    }
    this.requestImg();
    if (sessionStorage.getItem("userId") && sessionStorage.getItem("Leix")) {
      console.log("已登录！");
      this.$router.push("/");
    } else {
      sessionStorage.clear();
    }
  },
  methods: {
    //倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        //console.log(this.btntxt)
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    timerr() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        console.log(this.btntxt);
        setTimeout(this.timerr, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    //  获取图形验证码
    requestImg() {
      let self = this;
      this.$axios
        .get("/verify/image")
        .then((response) => {
          self.image = response.data.data.image;
          self.form.imageVerifyId = response.data.data.id;
          self.newForm.imageVerifyId = response.data.data.id;
          self.cellphone.imageVerifyId = response.data.data.id;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //  点击刷新图形验证码
    requestRefreshImg() {
      this.rotate = !this.rotate;
      this.requestImg();
    },
    //   跳转到忘记密码弹窗
    requestForget() {
      this.forgetDialog = true;
      this.requestImg();
    },

    //  获取手机验证码
    requestCode() {
      // this.isDisable = true
      // setTimeout(() => {
      //     this.isDisable = false
      // }, 1000)
      if (this.newForm.username.length === 0) {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
        this.$root.warn("请先输入用户名");
        return false;
      } else if (this.newForm.imageVerifyCode.length === 0) {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
        this.$root.warn("请先输入图形验证码");
        return false;
      }
      this.$axios
        .get("/verify/sms", {
          params: {
            imageVerifyCode: this.newForm.imageVerifyCode,
            imageVerifyId: this.newForm.imageVerifyId,
            username: this.newForm.username,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.time = 60;
            this.timer();
            //this.newForm.smsVerifyId = response.data.data
            //self.form.imageVerifyId、
            this.newForm.smsVerifyId = response.data.data;
          } else {
            if (response.data.data === "图形验证码错误") {
              this.$root.warn("图形验证码错误");
              this.newForm.imageVerifyCode = "";
              this.requestImg();
              return false;
            } else if (response.data.data === "图形验证码已过期") {
              this.$root.warn("图形验证码已过期");
              this.requestImg();
              this.newForm.imageVerifyCode = "";
            } else if (response.data.data === "验证码发送过于频繁") {
              this.$root.warn("每分钟仅能请求一次验证码，请稍后再试!");
              this.requestImg();
              this.newForm.imageVerifyCode = "";
            } else if (
              response.data.data ===
              "account [" +
                this.newForm.username +
                "] by [username] not exists."
            ) {
              this.$root.warn("该用户名不存在！");
              this.requestImg();
              this.newForm.imageVerifyCode = "";
            } else {
              this.$message({
                message: "发送失败",
                type: "warning",
              });
              this.requestImg();
              this.newForm.imageVerifyCode = "";
            }
          }
        });
    },
    //  获取手机验证码  (手机号登录)
    loginCode() {
      console.log(this.cellphone);
      this.$axios
        .get("/verify/sms/login", {
          params: {
            imageVerifyCode: this.cellphone.imageVerifyCode,
            imageVerifyId: this.cellphone.imageVerifyId,
            cellphone: this.cellphone.cellphone,
          },
        })
        .then((response) => {
          console.log(response);

          if (this.cellphone.imageVerifyCode.length === 0) {
            //this.$root.warn("请先输入图形验证码");
            this.cellphone.cellphone = "";
            return;
          } else {
            if (response.data.status === 200) {
              this.time = 60;
              this.timerr();
              this.cellphone.smsVerifyId = response.data.data;
            } else {
              let Taext =
                response.data.data.substring(0, 7) === "account"
                  ? "手机号不存在"
                  : response.data.data;

              console.log(Taext);
              this.requestImg();
              if (Taext === "验证码发送过于频繁") {
                this.$root.warn("每分钟仅能请求一次验证码，请稍后再试!");
              } else {
                this.$root.warn(Taext);
              }
              this.cellphone.imageVerifyCode = "";
            }
          }
        });
    },
    //  提交忘记密码
    forgetPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.newForm.newPassword !== this.newForm.confirmPassword) {
            this.$root.warn("密码不一致 请重新输入！");
            this.newForm.imageVerifyCode = "";
            this.newForm.newPassword = "";
            this.newForm.confirmPassword = "";
            this.requestImg();
            return false;
          }
          this.$axios
            .put("/account/password/forgot", this.newForm)
            .then((response) => {
              console.log(response);
              if (response.data.status === 200) {
                this.$root.success("修改成功");
                this.forgetDialog = false;
              } else {
                if (response.data.data.imageVerifyCode === "图形验证码已过期") {
                  this.$root.warn("图形验证码已过期");
                  this.requestImg();
                  this.newForm.imageVerifyCode = "";
                } else if (response.data.status === 406) {
                  this.$root.warn("用户名错误");
                  this.newForm.imageVerifyCode = "";
                } else {
                  this.$root.warn("修改失败");
                  this.newForm.imageVerifyCode = "";
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },
    //  返回
    back(formName) {
      this.$refs[formName].resetFields();
      this.forgetDialog = false;
      this.centerDialogVisible = true;
    },
    onSubmit(formName) {
      this.onSubmitButton = true;
      setTimeout(() => {
        this.onSubmitButton = false;
      }, 2000);
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .put("/login", self.form)
            .then(function(res) {
              console.log(res);
              if (res.data.status === 200) {
                sessionStorage.clear();

                let RolesArr = [];
                let Rolest = res.data.data.roles;
                Rolest.forEach(function(element) {
                  element.permissions.forEach(function(el) {
                    RolesArr.push(el.permission);
                  });
                });
                sessionStorage.setItem("Roles", JSON.stringify(RolesArr));
                if (res.data.data.owner) {
                  sessionStorage.setItem("userId", res.data.data.id);
                  sessionStorage.setItem("ID", res.data.data.ownerId);
                  sessionStorage.setItem("user", res.data.data.username);
                  sessionStorage.setItem("Leix", res.data.data.owner);
                  sessionStorage.setItem("Token", res.headers.token);
                  self.$message({
                    message: "登录成功",
                    type: "success",
                  });
                  self.accountId = res.data.data.ownerId;
                  self.$router.push({
                    path: "/",
                    query: { userId: self.accountId },
                  });
                  console.log(self.accountId);
                }
              } else {
                self.$message.error(res.data.data);
                if (res.data.data === "账号不存在") {
                  self.form.username = "";
                  self.form.password = "";
                  self.form.imageVerifyCode = "";
                } else if (res.data.data === "密码错误") {
                  self.form.password = "";
                } else if (res.data.data === "图形验证码错误") {
                  self.form.imageVerifyCode = "";
                } else {
                  self.form.imageVerifyCode = "";
                }
                self.requestRefreshImg();
                //alert("登录失败:" + res.data.status)
              }
            })
            .catch(function(error) {
              self.$message.error("登录失败");
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },
    telSubmit(formName) {
      this.onSubmitButton = true;
      setTimeout(() => {
        this.onSubmitButton = false;
      }, 2000);
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .put("/login/cellphone", self.cellphone)
            .then(function(res) {
              if (res.data.status === 200) {
                sessionStorage.clear();
                let RolesArr = [];
                let Rolest = res.data.data.roles;
                Rolest.forEach(function(element) {
                  element.permissions.forEach(function(el) {
                    RolesArr.push(el.permission);
                  });
                });
                sessionStorage.setItem("Roles", JSON.stringify(RolesArr));
                if (res.data.data.owner) {
                  sessionStorage.setItem("userId", res.data.data.id);
                  sessionStorage.setItem("ID", res.data.data.ownerId);
                  sessionStorage.setItem("user", res.data.data.username);
                  sessionStorage.setItem("Leix", res.data.data.owner);
                  sessionStorage.setItem("Token", res.headers.token);
                  self.$message({
                    message: "登录成功",
                    type: "success",
                  });
                  self.accountId = res.data.data.ownerId;
                  self.$router.push({
                    path: "/",
                    query: { userId: self.accountId },
                  });
                }
              } else if (res.data.data === "图形验证码错误") {
                self.$root.warn(res.data.data);
                self.form.imageVerifyCode = "";
                self.requestImg();
                return;
              } else {
                console.log(res.data);
                let text = res.data.data.smsVerifyId ? ": 短信验证码错误" : "";
                if (res.data.data.smsVerifyId) {
                  self.$message.error("短信验证码错误");
                } else {
                  self.$message.error(res.data.data);
                }

                self.cellphone.imageVerifyCode = "";
                self.cellphone.smsVerifyCode = "";
                // self.cellphone.cellphone = ''
                self.requestImg();
                //alert("登录失败:" + res.data.status)
              }
            })
            .catch(function(error) {
              self.$message.error(res.data.data);
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.Transition {
  transition: all 1s;
}

.go {
  transform: rotate(360deg);
  transition: all 1s;
}
body {
  height: 100%;
}
.img {
  width: 32px;
  height: 32px;
  margin-left: 4px;
  animation-name: rotate;
  /*animation-duration: 1s;*/
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.login {
  background: url("../assets/loginbj.jpg") no-repeat;
  height: 100vh;
  background-size: cover;

  .logo {
    height: 25vh;
    position: relative;

    img {
      position: absolute;
      bottom: 75px;
      left: 50%;
      margin-left: -425px;
    }
  }

  .loginbox {
    width: 900px;
    margin: 0 auto;
    background: #ffffff url(../assets/loginbox.jpg) no-repeat -74px -18px;
    height: 490px;
    border-radius: 8px;
    border: 0;
    position: relative;

    .fenj {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 45%;
      height: 100%;
      background: url(../assets/fj1.jpg) no-repeat center center;
      background-size: 100%;
    }

    .logoform {
      width: 45%;
      position: absolute;
      right: 100px;
      top: 120px;

      .reglink {
        float: right;
        font-size: 12px;
        text-decoration: none;
        color: #e6a23c;
      }
    }
  }

  .footer {
    text-align: center;
    height: 10vh;
    line-height: 28px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
  }
}
.text {
  color: #ffffff;
}
@import "../styles/common_responsive.less";
</style>
