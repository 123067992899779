const urlSearch = function() {
    var url = window.location.href;
    var search = '?'+url.split('?')[1]
    var theRequest = new Object();
    if (search.indexOf("?") != -1) {
        var str = search.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
        }
    }
    return theRequest
};
export default urlSearch